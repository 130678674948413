import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";
import PricingComparison from "../components/pricingcomparisons";

function Home() {
  // Get url hash
  const { hash } = useLocation();

  const [isMonthlyPricing, setIsMonthlyPricing] = useState(true);

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/");
    }
  }, [hash]);

  function setPricingMode(_pricingmode) {
    if (_pricingmode === "monthly") {
      setIsMonthlyPricing(true);
    } else if (_pricingmode === "yearly") {
      setIsMonthlyPricing(false);
    } else {
      return;
    }
  }

  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | Digital asset protection</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-4 display-3 position-relative z-index-2">Protection for your digital assets</h1>
              <p className="mb-5 w-lg-75 lead">
                Actively protect your Domains, SSL/TLS certificates, DNS records, and other assets against spoofing attacks.
              </p>
              <div className="d-flex justify-content-lg-start justify-content-center mb-1">
                <a className="btn mb-2 btn-warning  btn-lg d-flex align-items-center" href="https://zuratrust.typeform.com/to/UqrUeKsv">
                  Try Premium plan for free
                  <span className="material-symbols-rounded align-middle ms-2">arrow_forward</span>
                </a>
              </div>
              <blockquote className="blockquote d-none d-sm-block ms-sm-2 mt-4">
                <footer className="blockquote-footer">
                  <small>Premium features active for 1 month</small>
                </footer>
              </blockquote>
              {/* <small className="d-none d-sm-block ms-sm-2">
                  Premium features active for 1 month
                </small> */}
              {/* <div className="mb-5 d-flex align-items-center justify-content-lg-start justify-content-center">
                <div>
                  <a
                    href="https://www.youtube.com/"
                    className="glightbox size-60 hover-lift btn btn-warning p-0 rounded-circle d-flex align-items-center justify-content-center"
                    data-gallery="videoGallery"
                  >
                    <span className="fs-1 align-middle material-symbols-rounded">
                      play_arrow
                    </span>
                  </a>
                </div>
                <small className="d-none d-sm-block ms-sm-2">
                  See VbZ in action
                </small>
              </div> */}
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img src="/assets/img/chess-home.png" className="img-fluid rounded-5" alt="" />
                </div>
                {/* <div
                                className="bg-white rotate-4 shadow-lg position-absolute end-0 top-0 width-240 h-auto text-dark rounded-4 p-3">
                                <div>
                                    <div className="d-flex align-items-center"><span
                                            className="flex-shrink-0 width-40 height-40 justify-content-center align-items-center d-flex rounded-circle bg-opacity-25 bg-warning text-warning"><span
                                                className="material-symbols-rounded align-middle fs-3">workspaces</span></span>
                                        <div className="flex-grow-1 ps-3">
                                            <h6 className="mb-0 text-reset">Easy collaboration with remote teams</h6>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                {/* <div
                                className="bg-white rotate-n4 shadow-lg position-absolute start-0 bottom-0 width-200 h-auto text-dark rounded-4 p-3">
                                <div>
                                    <div className="d-flex align-items-center"><span
                                            className="flex-shrink-0 width-40 height-40 justify-content-center align-items-center d-flex rounded-circle bg-opacity-25 bg-primary text-primary"><span
                                                className="material-symbols-rounded align-middle fs-3">task</span></span>
                                        <div className="flex-grow-1 ps-3">
                                            <h6 className="mb-0 text-reset">Manage your business</h6>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}

      {/* ::Features:: */}
      <section className="position-relative">
        <div className="container py-9 py-lg-11">
          <div className="row">
            <div className="col-md-4 mb-6 mb-md-0">
              <div className="text-center">
                <div className="mb-4 text-primary">
                  <span className="material-symbols-rounded align-middle fs-1">insights</span>
                </div>
                <h5 className="mb-3">Traceback</h5>
                <p className="mb-0 px-xl-5">
                  Check protection results and changes for up to 1 year, so you can trace back to potential attack actions in the past.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-6 mb-md-0">
              <div className="text-center">
                <div className="mb-4 text-success">
                  <span className="material-symbols-rounded align-middle fs-1">smart_toy</span>
                </div>
                <h5 className="mb-3">Automation</h5>
                <p className="mb-0 px-xl-5">
                  Integrate security notifications with your existing workflows via webhooks or any of the other 9 notification channels.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="mb-4 text-warning">
                  <span className="material-symbols-rounded align-middle fs-1">workspaces</span>
                </div>
                <h5 className="mb-3">Real-time Monitoring</h5>
                <p className="mb-0 px-xl-5">
                  Protection analysis checks for each asset are performed multiple times every day, so that you are the first to know
                  whether a suspicious changes occurs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End Features/:: */}

      {/* ::Section with image:: */}
      {/* <section className="position-relative">
            <div className="container py-9 py-lg-11 position-relative">
                <div className="row align-items-center">
                    <div className="col-md-6 position-relative ps-md-5 ps-lg-9 col-sm-9 mb-6 mb-md-0 order-md-last mx-md-auto"
                         >
                        <div className="row align-items-center">
                            <div className="col-10">
                                <div className="position-relative pb-7" >
                                    <img src="/assets/img/img1.jpg" className="img-fluid rounded-3" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="position-absolute z-index-1 me-3 width-160 w-md-50 w-lg-35 h-auto end-0 bottom-0 overflow-hidden shadow-lg rounded-3"
                             >
                            <img src="/assets/img/shots/07.png" className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 order-md-1 ms-md-auto">
                        <h2 className="position-relative mb-4 fs-1">Inbuilt chat
                            for your organization.</h2>
                        <p className="lead mb-4" >Lorem ipsum
                            is placeholder text commonly used in the graphic, print, and publishing.</p>
                        <ul className="list-unstyled mb-4 mb-lg-5">
                            <li className="d-flex mb-3 align-items-start"><span
                                    className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>Laboris
                                nisi ut aliquip ex ea commodo consequat.</li>
                            <li className="d-flex mb-3 align-items-start"><span
                                    className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>Officia
                                deserunt mollit anim id est laborum.</li>
                        </ul>
                        <div ><a className="fw-bold"
                                href="/#">Learn More About Features<span
                                    className="material-symbols-rounded fs-5 ms-2 align-middle lh-1">arrow_forward</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
      {/* ::/End Section with image/:: */}

      {/* ::Section with image:: */}
      {/* <section className="position-relative">
            <div className="container py-9 py-lg-11 position-relative">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-6 mb-md-0"  >
                        <div className="position-relative">
                            <img src="/assets/img/integrations.svg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 ms-lg-auto">
                        <h2 className="position-relative fs-1 mb-4" >Deploy anywhere
                            — with any data.</h2>
                        <p className="lead d-none d-lg-block mb-4"  >Lorem ipsum is
                            placeholder text commonly used in the graphic, print,
                            and publishing.</p>
                        <ul className="list-unstyled mb-5"  >
                            <li className="d-flex mb-3 align-items-start"><span
                                    className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>Ut
                                enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat.</li>
                            <li className="d-flex mb-3 align-items-start"><span
                                    className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>Excepteur
                                sint occaecat cupidatat sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </li>
                        </ul>
                        <div  ><a className="fw-bold"
                                href="/#">Learn More<span
                                    className="material-symbols-rounded fs-5 ms-2 align-middle lh-1">arrow_forward</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
      {/* ::/Section with image/:: */}

      {/* ::Characteristics + Fingertips:: */}
      <section className="position-relative overflow-hidden bg-dark text-white">
        <svg
          className="position-absolute start-0 bottom-0 w-100 fill-body-bg"
          height="48"
          preserveAspectRatio="none"
          viewBox="0 0 1200 145"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L50 16.9167C100 33.8333 200 67.6667 300 77.3333C400 87 500 72.5 600 62.8333C700 53.1667 800 48.3333 900 55.5833C1000 62.8333 1100 82.1667 1150 91.8333L1200 101.5V145H1150C1100 145 1000 145 900 145C800 145 700 145 600 145C500 145 400 145 300 145C200 145 100 145 50 145H0V0Z"
          ></path>
        </svg>
        <div className="container py-9 py-lg-11 position-relative z-index-1">
          <div className="row">
            <div className="col-xl-8 col-lg-10 mx-auto text-center">
              <h5 className="mb-4 text-warning">Characteristics</h5>
              <h2 className="display-5 mb-4 mb-lg-5">verifiedbyzura at your fingertips</h2>
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-md-6 mt-4">
              <div className="bg-white bg-opacity-10 rounded-4 p-4">
                <div>
                  <div className="d-flex align-items-start">
                    <span className=" border-info text-info">
                      <span className="material-symbols-rounded align-middle fs-1">insights</span>
                    </span>
                    <div className="flex-grow-1 ps-4">
                      <h5 className="mb-0">Logs</h5>
                      <p className="mb-0 mt-2 opacity-75">
                        Log data of every monitoring activity at your fingertips, and an history of up to 2 years.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="bg-white bg-opacity-10 rounded-4 p-4">
                <div>
                  <div className="d-flex align-items-start">
                    <span className=" border-warning text-warning">
                      <span className="material-symbols-rounded align-middle fs-1">workspaces</span>
                    </span>
                    <div className="flex-grow-1 ps-4">
                      <h5 className="mb-0">Interation</h5>
                      <p className="mb-0 mt-2 opacity-75">
                        Integrate with your own Business LOgic and Applications using customizable webhooks as notification channels.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="bg-white bg-opacity-10 rounded-4 p-4">
                <div>
                  <div className="d-flex align-items-start">
                    <span className=" border-primary text-primary">
                      <span className="material-symbols-rounded align-middle fs-1">smart_toy</span>
                    </span>
                    <div className="flex-grow-1 ps-4">
                      <h5 className="mb-0">Automation</h5>
                      <p className="mb-0 mt-2 opacity-75">
                        Automate security notifications with more that 9 channels and more on the way.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="bg-white bg-opacity-10 rounded-4 p-4">
                <div>
                  <div className="d-flex align-items-start">
                    <span className=" border-success text-success">
                      <span className="material-symbols-rounded align-middle fs-1">verified_user</span>
                    </span>
                    <div className="flex-grow-1 ps-4">
                      <h5 className="mb-0">Secure &amp; Reliable</h5>
                      <p className="mb-0 mt-2 opacity-75">Hosted on European Cloud Servers and ready to upscale to the sky. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="bg-white bg-opacity-10 rounded-4 p-4">
                <div>
                  <div className="d-flex align-items-start">
                    <span className=" border-danger text-danger">
                      <span className="material-symbols-rounded align-middle fs-1">credit_score</span>
                    </span>
                    <div className="flex-grow-1 ps-4">
                      <h5 className="mb-0">Affordable</h5>
                      <p className="mb-0 mt-2 opacity-75">Up to three plans available from free to Unlimited options at your choice. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="bg-white bg-opacity-10 rounded-4 p-4">
                <div>
                  <div className="d-flex align-items-start">
                    <span className=" border-light text-light">
                      <span className="material-symbols-rounded align-middle fs-1">contact_support</span>
                    </span>
                    <div className="flex-grow-1 ps-4">
                      <h5 className="mb-0">Support</h5>
                      <p className="mb-0 mt-2 opacity-75">
                        Service support managed by our team, no external contractors wasting your time.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End Characteristics + Fingertips/:: */}

      {/* ::User Case:: */}
      <section>
        <div className="container">
          <div className="bg-warning rounded-4 shadow-lg text-white py-7 px-4">
            <div className="row">
              <div className="col-md-10 col-lg-9 mx-auto text-center">
                <div className="width-300 mx-auto h-auto mb-4">
                  <img src="/assets/img/this-could-be-you.svg" className="img-fluid" alt="" />
                </div>
                <h3 className="fw-normal flex-grow-1 lh-base mb-4 mb-lg-5">
                  “We suffered a phishing attack using a variation of our corporate domain address and lost a huge invoice from a customer.
                  Not only lost monetary resources but also trust from our partners. Protectiong our assets has become one the first step of
                  our security posture.”
                </h3>
                <div className="d-flex justify-content-center mt-auto align-items-center">
                  <div className="flex-shrink-0 width-60 shadow rounded-circle">
                    <img src="/assets/img/avatars/default-avatar.png" className="img-fluid rounded-pill" alt="" />
                  </div>
                  <div className="ps-3 text-start">
                    <h5 className="mb-1">Hans Christian</h5>
                    <small className="text-white text-opacity-75">CTO - Redacted Company</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End User Case/:: */}

      {/* ::Pricing & Comparison:: */}
      <section className="position-relative overflow-hidden" id="pricing">
        <div className="position-absolute start-0 bottom-0 w-100 h-50 bg-style-1"></div>
        <div className="container py-9 py-lg-11 position-relative">
          <div className="row mb-5 mb-lg-7">
            <div className="col-lg-8 mx-auto text-center">
              <h5 className="mb-4">Service options</h5>
              <h2 className="display-5 mb-0">Plain simple pricing plans</h2>
              <div class="btn-group mt-5">
                <input
                  type="radio"
                  class="btn-check"
                  name="options-outlined"
                  id="monthly-pricing"
                  autocomplete="off"
                  checked={isMonthlyPricing}
                  onClick={() => setPricingMode("monthly")}
                />
                <label class="btn btn-outline-secondary" for="monthly-pricing">
                  Monthly pricing
                </label>

                <input
                  type="radio"
                  class="btn-check"
                  name="options-outlined"
                  id="yearly-pricing"
                  autocomplete="off"
                  checked={!isMonthlyPricing}
                  onClick={() => setPricingMode("yearly")}
                />
                <label class="btn btn-outline-secondary" for="yearly-pricing">
                  Yearly pricing
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-7">
            <div className="row align-items-start">
              <div className="col-lg-4 col-sm-10 mx-auto">
                <div className="card mb-4 mb-lg-0 shadow-lg rounded-4 border-0 overflow-hidden">
                <span className="badge bg-none rounded-bottom-0 py-3 fs-6"><br/></span>
                  <div className="px-4 py-4">
                    <h5 className="mb-2">Starter</h5>
                    <p className="mb-0 text-muted">Full protection for one domain asset with low frequence monitoring (low recurrence) and community support.</p>
                  </div>
                  <div className="card-body pt-0 pb-4 px-4">
                    <h1 className="display-5">
                      <span className="fw-light small"></span> Free
                    </h1>
                    <small className="text-muted font-monospace mb-4 d-block">No credit card needed</small>

                    {/* <button
                      type="button"
                      className="w-100 btn btn-lg btn-secondary hover-lift"
                    >
                      Get Starter Plan
                    </button> */}
                    <a
                      className="btn mb-2 btn-secondary btn-lg d-flex align-items-center w-100 hover-lift text-center justify-content-center"
                      href="https://app.verifiedbyzura.com/#starter-plan"
                    >
                      Get Starter Plan
                    </a>
                    <ul className="list-unstyled mb-0 pt-4">
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        One domain
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Best Effort Monitoring Frequence
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Full protection
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Email Notification Channel
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-10 mx-auto">
                <div className="card mb-4 mb-lg-0 shadow-lg rounded-4 border-0 overflow-hidden">
                  <span className="badge bg-warning rounded-bottom-0 py-3 fs-6">Best value</span>
                  <div className="px-4 py-4">
                    <h5 className="mb-2">Premium</h5>
                    <p className="text-muted mb-0">Full protection for up to 4 domain assets with guaranteed daily monitoring frequence and standard support.</p>
                  </div>
                  <div className="card-body pt-0 pb-4 px-4">
                    <h1 className="display-5">
                      <span className="fw-light small">€</span>
                      {isMonthlyPricing ? (
                        <>45</>
                      ) : (
                        <>
                          430{" "}
                          <span className="text-muted fs-4">
                            <s><span className="fw-light small">€</span>540</s>
                          </span>
                        </>
                      )}
                    </h1>
                    <small className="text-muted font-monospace mb-4 d-block">
                      {isMonthlyPricing ? "Per Month" : "Per Year"}
                    </small>
                    {/* <button
                      type="button"
                      className="w-100 btn btn-lg btn-warning hover-lift"
                    >
                      Get Premium Plan
                    </button> */}
                    <a
                      className="btn mb-2 btn-warning btn-lg d-flex align-items-center w-100 hover-lift text-center justify-content-center"
                      href={
                        isMonthlyPricing
                          ? "https://app.verifiedbyzura.com/#premium-plan-monthly"
                          : "https://app.verifiedbyzura.com/#premium-plan-yearly"
                      }
                    >
                      Get Premium Plan
                    </a>
                    <ul className="list-unstyled mb-0 pt-4">
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Up to 4 domain assets protected
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Guaranteed Monitoring Frequence
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Full protection
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        All Notification Channels
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Analysis logs with limited history
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Standard Support
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-10 mx-auto">
                <div className="card shadow-lg rounded-4 border-0 overflow-hidden">
                <span className="badge bg-none rounded-bottom-0 py-3 fs-6"><br/></span>
                  <div className="px-4 py-4">
                    <h5 className="mb-2">Premium Unlimited</h5>
                    <p className="text-muted mb-0">Unlimited domain assets with guaranteed daily monitoring frequence and premium support.</p>
                  </div>
                  <div className="card-body pt-0 pb-4 px-4">
                    <h1 className="display-5">
                      <span className="fw-light small">€</span>{" "}
                      {isMonthlyPricing ? (
                        <>240</>
                      ) : (
                        <>
                          2500{" "}
                          <span className="text-muted fs-4">
                            <s><span className="fw-light small">€</span>2880</s>
                          </span>
                        </>
                      )}
                    </h1>
                    <small className="text-muted font-monospace mb-4 d-block">
                      {isMonthlyPricing ? "Per Month" : "Per Year"}
                    </small>
                    {/* <button
                      type="button"
                      className="w-100 btn btn-lg btn-secondary hover-lift"
                    >
                      Get Premium Unlimited Plan
                    </button> */}
                    <a
                      className="btn mb-2 btn-secondary btn-lg d-flex align-items-center w-100 hover-lift text-center justify-content-center"
                      href={
                        isMonthlyPricing
                          ? "https://app.verifiedbyzura.com/#premium-unlimited-plan-monthly"
                          : "https://app.verifiedbyzura.com/#premium-unlimited-plan-yearly"
                      }
                    >
                      Get Premium Unlimited Plan
                    </a>
                    <ul className="list-unstyled mb-0 pt-4">
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Unlimited domain assets protected
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Guaranteed Monitoring Frequence
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Full protection
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        All Notification Channels
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Analysis logs with full history
                      </li>
                      <li className="d-flex align-items-center mb-3">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">check_circle</span>
                        Premium Support
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PricingComparison />

          {/* <div className="text-center"><a href="/pricing">Learn More about Pricing <span
                            className="material-symbols-rounded align-middle ms-1">arrow_forward</span></a>
                </div> */}
        </div>
      </section>
      {/* ::/End Pricing & Comparison/:: */}

      {/* ::Notification Channels:: */}
      <section className="position-relative">
        <div className="container py-9 py-lg-11">
          <div className="row">
            <div className="col-lg-12 mx-auto text-center">
              <h5 className="mb-4">Notification Channels</h5>
              <h1 className="w-lg-75 mx-auto display-5 mb-5">Receive protection notifications on multiple channels</h1>
              <div className="row mb-5 justify-content-center">
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/email.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/sms.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/slack.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/whatsapp.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/telegram.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/webhooks.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/elasticopensearch.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/awscloudwatch.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
                <div className="col-4 col-sm-auto mb-4">
                  <a href="/" className="d-block py-4 px-4 bg-style-1 hover-lift rounded-4 card border-0">
                    <div className="width-40 mx-auto h-auto">
                      <img src="/assets/img/integrations/discord.svg" className="img-fluid" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="text-center">
                <Link to="/features#notification-channels">View all available notification channels</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End Notification Channels/:: */}

      {/* ::Call to Action - Free Premium 1 month:: */}
      <section className="position-relative" id="try-1-month-free">
        <div className="bg-dark w-100 h-50 bottom-0 start-0 position-absolute"></div>
        <div className="container position-relative">
          <div className="px-4 py-7 py-lg-9 rounded-4 position-relative z-index-1 overflow-hidden text-white bg-secondary">
            <div className="row position-relative">
              <div className="col-lg-9 col-md-10 mx-auto text-center">
                <h5 className="mb-4">Start protecting your digital assets Today</h5>
                <h2 className="display-4 mb-5">A complete solution for your cyber protection needs</h2>
                <div className="d-flex flex-wrap justify-content-center">
                  <div>
                    <a className="btn btn-white btn-lg hover-lift me-3" href="https://zuratrust.typeform.com/to/UqrUeKsv">
                      Get your Premium plan for free for 1 month
                      <span className="material-symbols-rounded fs-5 ms-2 align-middle lh-1">arrow_forward</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*}::End Call to Action - Free Premium 1 month:: */}
      <Footer />
    </>
  );
}

export default Home;
