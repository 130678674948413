
  // Scroll to tag area in document
  export function navigateToAnchor(_anchor) {
    // if anchor is emppty, scroll to top
    if (_anchor === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = _anchor.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }


