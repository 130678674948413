import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {useLocation} from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import Header from "../components/header";
import Footer from "../components/footer";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";

function Legal() {
  // Get url hash
  const { hash } = useLocation();

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/legal/terms-of-use" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/legal/terms-of-use");
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | Legal Scope</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-4 display-3 position-relative z-index-2">
                Our Terms Of Use
              </h1>
              <p className="mb-5 w-lg-75 ">
                current version: v1.4 as of 01-2022
              </p>
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img
                    src="/assets/img/balance-legal.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}

      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h2>These are Our Terms of Use.</h2>
              <h3>
                (Currently this information is only available is spanish, other
                languages are WIP.)
              </h3>
            </div>
            <div className="col-lg-12 text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <p>
                El presente documento recoge las condiciones de uso que
                regularán el servicio prestado por Zura Technologies SLU, con
                CIF B-75179986 y sede social en Deusto Entrepreneurship Center –
                Innogune – Mundaiz bidea 50, 20012 Donostia-San Sebastián a
                través del servicio de protección y monitorización de activos
                digitales (en adelante VerifiedByZura).
              </p>
              <p>
                El presente documento constituye un contrato legal entre el
                usuario registrado y VerifiedByZura. El uso de VerifiedByZura y
                la aceptación de los términos y condiciones aquí contemplados le
                facultan a utilizar VerifiedByZura.
              </p>
              <p>
                El usuario entiende que la información contenida en este
                documento tanto en referencia a las especificaciones del
                servicio prestado, como a las condiciones generales de
                contratación y perfeccionamiento del contrato, son bastantes y
                suficientes para la exclusión de error en la formación del
                consentimiento, y por ello, todas las obligaciones aquí
                dispuestas. No obstante, los responsables de VerifiedByZura, se
                comprometen a poner en conocimiento del usuario cualquier otra
                especificación técnica del servicio y jurídica referente a la
                relación contractual, a primer requerimiento por cualquier medio
                que deje constancia.
              </p>
              <h2>DEFINICIONES:</h2>
              <h3>A los efectos del presente documento se entenderá por:</h3>
              <h3>Cliente:</h3>
              <p>
                Empresa u organización que contrata a VerifiedByZura para
                garantizar la protección a través de la monitorización y
                detecciópn de riesgos de seguridad en los activos digitales
                tales como dominios, certificados SSL/TLS, registros DNS,...
              </p>
              <h3>Usuario registrado:</h3>
              <p>
                Es el usuario/a que dispone de una cuenta en VerifiedByZura,
                rellena el formulario de alta y acepta estas condiciones de uso
                a fin de poder utilizar sus funcionalidades.
              </p>
              ------
              <h3>Comunicación:</h3>
              <p>
                Intercambio de información que se produce mediante correo
                electrónico.
              </p>
              <h3>Emisor:</h3>
              <p>
                es el usuario registrado que envía una comunicación al receptor.
              </p>
              <h3>Receptor:</h3>
              <p>
                es el usuario registrado que recibe la comunicación enviada por
                el emisor.
              </p>
              <h3>Dispositivo móvil:</h3>
              <p>
                Aquel dispositivo que cumpla con los requisitos establecidos
                para poder instalar VerifiedByZura. Los responsables de
                VerifiedByZura informarán en todo momento de los requisitos y
                software que deben tener los dispositivos móviles para su
                correcto funcionamiento.
              </p>
              <h3>Correo verificado:</h3>
              <p>
                Es aquel correo electrónico en el que tanto el emisor como el
                receptor pueden confiar, puesto que el emisor ha utilizado
                VerifiedByZura, bien introduciendo el código que le ha sido
                proporcionado, bien verificando la ventana de validez (en caso
                de introducción incorrecta del código) y por tanto, éste ha sido
                enviado superando el doble control establecido por
                VerifiedByZura.
              </p>
              <h3>Correo rechazado:</h3>
              <p>
                Es aquel correo electrónico en el que tanto el emisor como el
                receptor no pueden confiar, puesto que el emisor no lo ha
                verificado o directamente lo ha rechazado.
              </p>
              <h3>Administrador:</h3>
              <p>
                es el responsable de las cuentas de los usuarios registrados de
                su empresa (Cuentas Enterprise). Éste será quien decida quiénes
                van a ser los usuarios registrados de la misma y van a tener
                acceso a la aplicación. El administrador, al no ser un usuario
                registrado, sólo tendrá acceso a información estadística sobre
                el uso de la “Cuenta Enterprise”, pero en ningún caso podrá
                conocer quiénes son los emisores y receptores de los correos
                electrónicos, fecha en la que se hayan enviado, o cuál es el
                asunto con el que se remitieron dichas comunicaciones.
              </p>
              <h3>Ventana de validez:</h3>
              <p>
                es un mecanismo de comprobación que genera la aplicación en el
                momento en el que el emisor ha introducido de forma incorrecta
                el código que le ha sido proporcionado, a fin de darle la
                oportunidad de verificar o rechazar la comunicación que
                pretendía enviar.
              </p>
              <h3>Regeneración del código:</h3>
              <p>
                es el cambio de código que se produce cuando transcurre cierto
                tiempo. Esto es, en un lapso breve de tiempo el código antiguo
                que fue proporcionado desaparece y aparece un nuevo código que
                debe ser utilizado por parte del emisor (se regenera).
              </p>
              <h2>OBJETO Y DESCRIPCIÓN DEL SERVICIO</h2>
              <p>
                VerifiedByZura es una aplicación móvil que ofrece una forma de
                verificación de la identidad del emisor de correos electrónicos,
                a fin de comprobar la veracidad de la comunicación y así evitar
                diferentes técnicas de fraude a través de dichas comunicaciones.
              </p>
              <p>
                Es decir, VerifiedByZura permite al receptor la comprobación de
                la veracidad de la comunicación así como permite al emisor la
                confirmación de dicha comunicación.
              </p>
              <p>
                El objetivo de VerifiedByZura es que tanto el emisor como el
                receptor puedan detectar aquellas comunicaciones que vayan
                destinadas al engaño, fraude, etc. o simplemente a asegurar las
                comunicaciones con la inclusión de dicho doble factor.
              </p>
              <h3>VerifiedByZura establece dos tipos de controles:</h3>
              <ul>
                <li>
                  <p>
                    Por un lado, el usuario al recibir el correo electrónico de
                    la supuesta persona emisora puede comprobar su veracidad,
                    observando que los datos del e-mail recibido coinciden con
                    los datos verificados en la aplicación.
                  </p>
                </li>
                <li>
                  <p>
                    Por otro lado, el emisor en el momento de envío del e-mail,
                    recibe una notificación confirmatoria de su envío a través
                    de la aplicación VerifiedByZura en su dispositivo móvil
                    (siempre y cuando no haya deshabilitado expresamente las
                    notificaciones push para la aplicación VerifiedByZura). De
                    esta manera, en el caso en el que la persona emisora no
                    hubiese enviado ese e-mail, comprobaría que existe un
                    tercero que ha utilizado su cuenta para hacerlo y podría
                    avisar al receptor de un posible ataque, fraude, etc. que
                    pudiese llegar a materializarse.
                  </p>
                </li>
              </ul>
              <p>
                Para ello, el emisor del mensaje, deberá incluir un código
                proporcionado por la aplicación en cada comunicación que vaya a
                enviar. De esta manera, VerifiedByZura, podrá verificar la
                seguridad del envío, anónimamente.
              </p>
              <p>
                Cada comunicación tendrá su propio código y éste a su vez se irá
                regenerando instantáneamente a fin de asegurar la no validez de
                códigos pasados en el futuro. Así pues, el emisor cuando vaya a
                enviar una comunicación deberá introducir el código que le
                proporciona la aplicación VerifiedByZura.
              </p>
              <ul>
                <li>
                  <p>Dicho código se regenerará cada cierto tiempo.</p>
                </li>
                <li>
                  <p>
                    Si el emisor introduce correctamente el código, el correo
                    electrónico se verificará automáticamente.
                  </p>
                </li>
                <li>
                  <p>
                    No obstante, en el caso de introducir erróneamente el código
                    (bien porque se ha confundido en algún carácter, bien porque
                    el código introducido ya se ha regenerado), la aplicación
                    generará un mecanismo de validación o comprobación (ventana
                    de validez) en el que el emisor tendrá la posibilidad de
                    verificar desde la aplicación dicho correo electrónico y por
                    tanto, que éste sea visible como verificado para el
                    receptor; o bien, rechazarlo y que aparezca como tal para el
                    receptor.
                  </p>
                </li>
              </ul>
              <p>
                Asimismo, en el caso en el que el emisor haya verificado un
                correo electrónico y posteriormente caiga en la cuenta de que no
                quería verificar dicha comunicación por no reconocerla como
                real, podrá cancelar la verificación del correo electrónico,
                cambiando el estado de dicha comunicación en la pantalla del
                receptor de correo electrónico: “ de correo verificado a correo
                rechazado”. A tal respecto, es importante señalar que
                VerifiedByZura no se hará en ningún caso responsable de la
                diligencia que hayan tenido tanto el emisor como el receptor, en
                las respectivas comunicaciones, tal y como se expondrá en el
                apartado de “OBLIGACIONES DE LOS USUARIOS”.
              </p>
              <p>
                Cabe decir que VerifiedByZura en ningún momento accede al
                contenido de los correos electrónicos. Los únicos datos que
                maneja VerifiedByZura, son los metadatos y cabeceras de las
                comunicaciones.
              </p>
              <h2>NECESIDAD DE REGISTRO</h2>
              <p>
                Para poder utilizar VerifiedByZura, la persona que lo desee
                deberá registrarse en la misma, a través del formulario
                establecido en la aplicación VerifiedByZura y facilitando los
                datos necesarios para la correcta gestión y administración del
                servicio. El usuario se registrará a través de sus credenciales
                de acceso (login/pass) en el que VerifiedByZura solicitará los
                siguientes datos: nombre, apellidos, número de teléfono, correo
                electrónico así como una fotografía, a fin de poder crear un
                perfil que distinguirá a los usuarios registrados. El usuario
                registrado podrá, en su perfil establecido en VerifiedByZura,
                modificar dichos datos.
              </p>
              <p>
                Por otro lado, para ser usuario registrado es necesario ser
                mayor de edad y estar capacitado legalmente para prestar
                consentimiento.
              </p>
              <p>
                Finalmente, cabe destacar que los datos introducidos por el
                usuario deberán ser exactos, actuales y veraces. El usuario
                registrado será responsable en todo momento de la veracidad y de
                la custodia de sus datos de acceso a VerifiedByZura, asumiendo
                en consecuencia cualesquiera daños y perjuicios que pudieran
                derivarse de su inexactitud, uso indebido, así como de la
                cesión, revelación o extravío. En todo caso, los responsables de
                VerifiedByZura, se reservan el derecho exclusivo a rechazar
                discrecionalmente cualquier solicitud de registro o anular
                aquellas que a su criterio incumplan las presentes Condiciones.
              </p>
              <h2>MODALIDADES DE SERVICIO</h2>
              <p>
                Existen cuatro modalidades de servicio, distinguiéndose el plan
                gratuito y los planes de pago.
              </p>
              <h3>Free Plan:</h3>
              <p>
                Se trata de un plan gratuito que es accesible a todo el mundo y
                está condicionado a restricciones de uso. Éste permite a su
                usuario recibir correos ilimitadamente y poder comprobar su
                veracidad.
              </p>
              <h3>Lite plan:</h3>
              <p>
                Se trata de un plan mensual que cuenta con servicios limitados.
                Éste podrá pagarse mensualmente, abonando la cantidad
                correspondiente establecida a la hora de contratación de
                VerifiedByZura. Éste plan permite recibir correos ilimitadamente
                y verificar un número concreto de correos enviados.
              </p>
              <h3>Pro plan:</h3>
              <p>
                Se trata de un plan mensual, al igual que LITE, pero que sin
                embargo, cuenta con servicios ilimitados de verificación.
                abonando la cantidad correspondiente establecida a la hora de
                contratación de VerifiedByZura.
              </p>
              <h3>Enterprise plan:</h3>
              <p>
                Se trata de un plan anual dirigido a empresas en el que
                VerifiedByZura se adapta a las necesidades que éstos presenten.
              </p>
              <h2>OBLIGACIONES DE LOS USUARIOS</h2>
              <p>
                Los usuarios y clientes registrados se comprometen a utilizar
                VerifiedByZura de conformidad con la ley, la moral y las buenas
                costumbres generalmente aceptadas y el orden público, así como a
                abstenerse de utilizar la misma con fines o efectos ilícitos,
                prohibidos en las presentes Condiciones, lesivos de los derechos
                e intereses de terceros.
              </p>
              <p>
                El usuario es el único responsable de la gestión del usuario y
                contraseña o sistema análogo de acceso a la aplicación (a modo
                enunciativo y no exhaustivo, huellas digitales, datos
                biométricos, etc.) y por tanto, se obliga a usarla de manera
                diligente, manteniéndola en todo momento en secreto y con las
                medidas de seguridad correspondientes. En consecuencia, será
                responsable de su adecuada custodia y confidencialidad,
                comprometiéndose a no cederla a terceros, de manera temporal o
                permanente, ni a permitir el acceso a los mencionados servicios
                y/o contenidos por parte de personas ajenas. Igualmente, se
                obliga a notificar VerifiedByZura cualquier hecho que pueda
                suponer un uso indebido de sus sistemas de acceso, como, a
                título enunciativo, su robo, extravío o el acceso no autorizado,
                con el fin de proceder a su inmediata cancelación. En
                consecuencia, mientras no efectúe la notificación anterior,
                VerifiedByZura quedará eximida de cualquier responsabilidad que
                pudiera derivarse del uso indebido de sus sistemas de acceso,
                siendo de su responsabilidad cualquier utilización ilícita de
                los contenidos y/o servicios de la aplicación por cualquier
                tercero ilegítimo.
              </p>
              <p>
                El usuario y el cliente están obligados a facilitar información
                veraz al cumplimentar con sus datos de carácter personal los
                formularios y a mantenerlos actualizados en todo momento de
                forma que responda, en cada momento, a la situación real del
                usuario. Así pues, ellos serán los únicos responsables de las
                manifestaciones falsas o inexactas que realicen y de los
                perjuicios que pudieran causar a VerifiedByZura o a terceros.
              </p>
              <p>
                El emisor de la comunicación, asimismo, es el único responsable
                de la introducción correcta del código y de la verificación de
                los correos electrónicos enviados. En el caso en el que éste
                fuera introducido erróneamente y el emisor posteriormente
                verificase la ventana de validación, sin atender a si ese era la
                comunicación que quería enviar, VerifiedByZura no podrá filtrar
                las comunicaciones y garantizar la seguridad en su envío, por lo
                que el cliente sería el único responsable de los daños y
                perjuicios que se pudieran originar. De la misma manera, el
                emisor será el único responsable de las consecuencias que
                pudieran derivarse del cambio de estado de un correo electrónico
                verificado a rechazado.
              </p>
              <p>
                En los casos anteriormente mencionados, los usuarios registrados
                se obligan a mantener indemne a VerifiedByZura, ante cualquier
                posible reclamación, multa, pena o sanción que pueda venir
                obligada a soportar como consecuencia del incumplimiento de las
                obligaciones, utilizar el sistema de VerifiedByZura sin las
                debidas cautelas establecidas con su propósito de doble factor
                de seguridad, que puedan cometerse por parte del usuario
                registrado, reservándose, además, VerifiedByZura, el derecho a
                solicitar la indemnización por daños y perjuicios que
                corresponda.
              </p>
              <p>
                Asimismo, cabe decir que VerifiedByZura, se reserva el derecho
                de anular la cuenta y el registro de aquellos usuarios que hagan
                un uso inapropiado de la misma o no respeten las observaciones y
                prohibiciones previstas por estas Condiciones de Uso.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Legal;
