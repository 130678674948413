import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";

function Header() {
  
  useEffect(() => {
    const navbarSitcky = document.querySelector(".navbar-sticky");
    //Header
    var headroom = new Headroom(navbarSitcky);
    headroom.init();
  });

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-sticky navbar-light">
        <div className="container-fluid position-relative">
          <Link className="navbar-brand" to="/">
            <div className="logo-light">
              <img src="/assets/img/logo.svg" className="img-fluid" alt="" />
            </div>
            <div className="logo-dark">
              <img
                src="/assets/img/logo-white.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </Link>
          <button
            // className="navbar-toggler border-0 p-0"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbarDefault"
            aria-controls="offcanvasNavbarDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="material-symbols-rounded align-middle">menu</span>
          </button>
  
          <div
            className="offcanvas offcanvas-start"
            data-bs-scroll="true"
            id="offcanvasNavbarDefault"
            tabIndex="-1"
            aria-labelledby="offcanvasNavbarDefaultLabel"
          >
            <div className="offcanvas-header justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="me-auto navbar-nav ms-xl-4">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown position-static">
                  <Link
                    className="nav-link dropdown-arrow"
                    to="#"
                    data-bs-toggle="dropdown"
                  >
                    Features
                    <span className="material-symbols-rounded align-middle lh-1 dropdown-arrow-icon">
                      expand_more
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-full">
                    <div className="row">
                      <div className="col-lg-3 me-lg-auto">
                        <h6 className="dropdown-header">Overview</h6>
                        <Link
                          className="dropdown-item py-3 mb-3"
                          to="/features"
                        >
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 shadow-sm size-40 d-flex align-items-center justify-content-center me-3 rounded-circle bg-white">
                              <span className="material-symbols-rounded align-middle fs-4 lh-1 text-secondary">
                                fact_check
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Overview</h6>
                              <small className="opacity-75 lh-sm">
                                Full list of features
                              </small>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-8 ps-lg-8 border-start-lg">
                        <h6 className="dropdown-header">Features in detail</h6>
                        <div className="row">
                          <div className="col-lg-6 mb-4 mb-lg-0">
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: "/features",
                                hash: "#protection-modes",
                              }}
                            >
                              Active monitoring
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: "/features",
                                hash: "#account-security",
                              }}
                            >
                              Guaranteed Protection Frequence
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: "/features",
                                hash: "#notification-channels",
                              }}
                            >
                              Notification Channels
                            </Link>
                      
                          </div>
                          <div className="col-lg-6 mb-4 mb-lg-0">
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: "/features",
                                hash: "#account-security",
                              }}
                            >
                              Account Security
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: "/features",
                                hash: "#logs",
                              }}
                            >
                              Analysis Logs
                            </Link>

                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: "/features",
                                hash: "#account-security",
                              }}
                            >
                              Support
                            </Link>
                            
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#pricing">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav ms-xl-auto">
                {/* <li className="nav-item dropdown mb-3 mb-lg-0"><Link className="nav-link dropdown-arrow" to="#"
                                data-bs-toggle="dropdown">Pages<span
                                    className="material-symbols-rounded align-middle lh-1 dropdown-arrow-icon">expand_more</span></Link>
                            <div className="dropdown-menu dropdown-menu-end"><Link className="dropdown-item"
                                    to="customers">Customers</Link><Link className="dropdown-item"
                                    to="typography">Typography</Link><Link className="dropdown-item" to="404">Custom
                                    404</Link><Link className="dropdown-item" to="demo-request">Demo request</Link><Link
                                    className="dropdown-item" to="signin">Sign In</Link><Link className="dropdown-item"
                                    to="signup">Sign Up</Link><Link className="dropdown-item"
                                    to="forgot-password">Forget password</Link></div>
                        </li> */}
                <li className="nav-item mb-3 mb-lg-0">
                  {/* <Link className="btn btn-warning btn-sm" to="demo-request">
                    Create your account
                    <span
                                className="align-middle material-symbols-rounded fs-5 ms-1 d-none d-xl-inline-block">
                                    arrow_forward</span>
                  </Link> */}
                  <a
                    className="btn btn-warning btn-sm"
                    href="https://app.verifiedbyzura.com"
                  >
                    Create your account
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://app.verifiedbyzura.com">
                    Sign In
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
