import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {useLocation} from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import Header from "../components/header";
import Footer from "../components/footer";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";

function Legal() {
  // Get url hash
  const { hash } = useLocation();

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/legal/cookie-policy" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/legal/cookie-policy");
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | Our Cookie Policy</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-4 display-3 position-relative z-index-2">
                Our Cookie Policy
              </h1>
              <p className="mb-5 w-lg-75 ">
                current version: v1.4 as of 01-2022
              </p>
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img
                    src="/assets/img/balance-legal.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}

      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h2>We use cookies and this is our usage policy.</h2>
              <h3>
                (Currently this information is only available is spanish, other
                languages are WIP.)
              </h3>
            </div>
            <div className="col-lg-12 text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <p>
                La presente política de cookies tiene por finalidad informarle
                de manera clara y precisa sobre las cookies que se utilizan en
                las páginas web públicas o privadas de verifiedbyzura.com.
              </p>
              <h3>¿Qué son las cookies?</h3>
              <p>
                Una cookie es un pequeño fragmento de texto que los sitios web
                que visita envían al navegador y que permite que el sitio web
                recuerde información sobre su visita, como su idioma preferido y
                otras opciones, con el fin de facilitar su próxima visita y
                hacer que el sitio le resulte más útil. Las cookies desempeñan
                un papel muy importante y contribuyen a tener una mejor
                experiencia de navegación para el usuario.
              </p>
              <h3>Tipos de cookies</h3>
              <p>
                Según quién sea la entidad que gestione el dominio desde dónde
                se envían las cookies y se traten los datos que se obtengan, se
                pueden distinguir dos tipos: cookies propias y cookies de
                terceros.
              </p>
              <p>
                Existe también una segunda clasificación según el plazo de
                tiempo que permanecen almacenadas en el navegador del cliente,
                pudiendo tratarse de cookies de sesión o cookies persistentes.
              </p>
              <p>
                Por último, existe otra clasificación con cinco tipos de cookies
                según la finalidad para la que se traten los datos obtenidos:
                cookies técnicas, cookies de personalización, cookies de
                análisis, cookies publicitarias y cookies de publicidad
                comportamental.
              </p>
              <p>
                Para más información a este respecto puede consultar la Guía
                sobre el uso de las cookies de la Agencia Española de Protección
                de Datos.
              </p>
              <h3>Cookies utilizadas en esta web</h3>
              <p>
                A continuación se identifican las cookies que están siendo
                utilizadas en este portal así como su tipología y función:
              </p>
              <p>
                Esta página web utiliza Google Analytics, un servicio de
                analítica web desarrollada por Google, que permite la medición y
                análisis de la navegación en las páginas web. En su navegador
                podrá observar cookies de este servicio. Según la tipología
                anterior se trata de cookies propias, de sesión y de análisis.
              </p>
              <p>
                A través de la analítica web se obtiene información relativa al
                número de usuarios que acceden a la web, el número de páginas
                vistas, la frecuencia y repetición de las visitas, su duración,
                el navegador utilizado, el operador que presta el servicio, el
                idioma, el terminal que utiliza y la ciudad a la que está
                asignada su dirección IP. Información que posibilita un mejor y
                más apropiado servicio por parte de este portal.
              </p>
              <p>
                Para garantizar el anonimato, Google convertirá su información
                en anónima truncando la dirección IP antes de almacenarla, de
                forma que Google Analytics no se usa para localizar o recabar
                información personal identificable de los visitantes del sitio.
                Google solo podrá enviar la información recabada por Google
                Analytics a terceros cuanto esté legalmente obligado a ello. Con
                arreglo a las condiciones de prestación del servicio de Google
                Analytics, Google no asociará su dirección IP a ningún otro dato
                conservado por Google.
              </p>
              <p>
                Por último, se descarga una cookie de tipo técnico y de sesión.
                Gestiona el consentimiento del usuario para el uso de las
                cookies en la página web, con el objeto de recordar aquellos
                usuarios que las han aceptado y aquellos que no, de modo que a
                los primeros no se les muestre información en la parte superior
                de la página al respecto.
              </p>
              <h3>Aceptación de la política de cookies</h3>
              <p>
                Pulsando el botón Aceptar se asume que usted acepta el uso de
                cookies.
              </p>
              <h3>Cómo modificar la configuración de las cookies</h3>
              <p>
                Usted puede restringir, bloquear o borrar las cookies de
                www.verifiedbyzura.com o cualquier otra página web utilizando su
                navegador. En cada navegador la operativa es diferente, puede
                consultar dicha información actualizada en la documentación del
                propio navegador.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Legal;
