import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {useLocation} from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import Header from "../components/header";
import Footer from "../components/footer";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";

function About() {
  // Get url hash
  const { hash } = useLocation();

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/404" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/404");
    }
  }, [hash]);
  
  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | 404</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-9 display-4 position-relative z-index-2">
                404 Page not found.
              </h1>
              <p className="mb-5 w-lg-75 ">
                We haven't found the page you are looking for
              </p>
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img
                    src="/assets/img/404.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}
      <Footer />
    </>
  );
}

export default About;
