import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// import logo from './logo.svg';
// import './App.css';
import Header from "../components/header";
import Footer from "../components/footer";
import ClientsCarousel from "../components/ClientsCarousel";
import MediaKit from "../components/mediakit";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";

function About() {
  // Get url hash
  const { hash } = useLocation();

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/about" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/about");
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | About Us</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-4 display-3 position-relative z-index-2">
                Who we are
              </h1>
              <p className="mb-5 w-lg-75 ">
                We founded Zura Technologies back in 2017.
              </p>
              <p className="mb-5 w-lg-75 ">
                Currently we are a fully remote company although our main office
                is located in the Deusto University Campus in Donostia - San
                Sebastian in the heart of the fantastic Basque Country, an
                emerging cybersecurity hub in Europe.
              </p>
              <p className="mb-5 w-lg-75 ">
                We build, market and manage cyber security protection products,
                easy and comprehensible tools against complex and evolving
                risks.
              </p>
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img
                    src="/assets/img/about2.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}

      <section className="position-relative bg-blur overflow-hidden">
        <svg
          className="position-absolute start-0 bottom-0 w-100 fill-body-bg"
          height="96"
          preserveAspectRatio="none"
          viewBox="0 0 1200 145"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L50 16.9167C100 33.8333 200 67.6667 300 77.3333C400 87 500 72.5 600 62.8333C700 53.1667 800 48.3333 900 55.5833C1000 62.8333 1100 82.1667 1150 91.8333L1200 101.5V145H1150C1100 145 1000 145 900 145C800 145 700 145 600 145C500 145 400 145 300 145C200 145 100 145 50 145H0V0Z"
            fill="currentColor"
          ></path>
        </svg>
        <div className="container pt-11 pt-lg-15 pb-9">
          <div className="row pb-8 pb-lg-11">
            <div className="col-lg-10 col-xl-8 mx-auto text-center">
              <h1 className="display-4 mb-3">
                Find Information About Us And Detailed Profiles On:
              </h1>
              <p className="lead mb-4"></p>
            </div>
          </div>
        </div>
      </section>

      <div className="container pb-9 pb-lg-11 position-relative mt-n12">
        <div className="row mb-4">
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card card-body p-4 border-0 shadow hover-lift">
              <div className="w-75 m-auto">
                <img
                  src="/assets/img/about/profile-crunchbase.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4 className="mb-3">Crunchbase</h4>
              <p className="mb-4">
                Crunchbase is the leading destination for company insights from
                early-stage startups to the Fortune 1000. Go to our profile
                there.
              </p>
              <div>
                <a
                  className="btn btn-light btn-sm"
                  href="https://www.crunchbase.com/organization/zura-making-emails-trustworthy"
                >
                  <span className="material-symbols-rounded align-middle fs-5 me-2">
                    arrow_forward
                  </span>
                  View more
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card card-body p-4 border-0 shadow hover-lift">
              <div className="w-75 m-auto">
                <img
                  src="/assets/img/about/profile-startupexplore.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4 className="mb-3">StartupXplore</h4>
              <p className="mb-4">
                Startupxplore is a platform that offers investment opportunities
                in companies with high growth potential. Also the largest
                startup community in Spain and one of the most active in Europe.
              </p>
              <div>
                <a
                  className="btn btn-light btn-sm"
                  href="https://startupxplore.com/es/startups/zura-making-emails-trustworthy"
                >
                  <span className="material-symbols-rounded align-middle fs-5 me-2">
                    arrow_forward
                  </span>
                  View more
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card card-body p-4 border-0 shadow hover-lift">
              <div className="w-75 m-auto">
                <img
                  src="/assets/img/about/profile-bcsc.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <h4 className="mb-3">Basque Cybersecurity Center</h4>
              <p className="mb-4">
                Basque CyberSecurity Centre (BCSC), is the organization
                designated by the Basque Government to promote cybersecurity in
                the Basque Country.
              </p>
              <div>
                <a
                  className="btn btn-light btn-sm"
                  href="https://www.basquecybersecurity.eus/es/actualidad-bcsc/libro-blanco-ciberseguridad-euskadi.html"
                >
                  <span className="material-symbols-rounded align-middle fs-5 me-2">
                    arrow_forward
                  </span>
                  View more
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
                <button type="button" className="btn btn-primary hover-lift">Lead More Apps<span
                        className="material-symbols-rounded align-middle fs-5 ms-2">more_horiz</span></button></div> */}
      </div>
      <section className="position-relative" id="contact-location">
        <div className="container z-index-1 position-relative mt-5 pb-5 pb-md-11">
          <div className="row">
            <div className="col-12">
              <div className="mb-9">
                <div>
                  <h3 className="mb-4 display-5 font-seminold">
                    {/* <a className="text-reset" href="https://goo.gl/maps/6GvTWXeeLTsvMhnP9"> */}
                    This is where our head office is.
                    {/* </a> */}
                  </h3>
                </div>
              </div>
              <div className="mb-4">
                <div>
                  <a
                    aria-label="Zura Technologies SL on Google Maps"
                    href="https://goo.gl/maps/6GvTWXeeLTsvMhnP9"
                  >
                    <img
                      src="/assets/img/about/location-map.png"
                      className="img-fluid rounded-5"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-md-0 mb-6">
              <div className="mb-4">
                <div>
                  <img
                    src="/assets/img/about/address.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
              </div>
              {/* <div className="font-bold mb-3">
                <p>Things to know</p>
              </div> */}
              <h4 className="font-semibold">
                * We are a remote company, take that in mind
              </h4>
              <h4 className="font-semibold">* Our Timezone is CET</h4>
              {/* <p className="text-lg leading-relaxed mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit...
              </p> */}
              {/* <div className="d-flex align-items-center">
                <div className="flex-shrink-0 size-40 rounded-circle me-3">
                  <img
                    src="/assets/img/avatars/female/2.jpg"
                    className="width-40 rounded-circle"
                    alt=""
                  />
                </div>
                <div className="fw-semibold small">Emily Doe</div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative bg-blur overflow-hidden">
        <svg
          className="position-absolute start-0 bottom-0 w-100 fill-body-bg"
          height="96"
          preserveAspectRatio="none"
          viewBox="0 0 1200 145"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L50 16.9167C100 33.8333 200 67.6667 300 77.3333C400 87 500 72.5 600 62.8333C700 53.1667 800 48.3333 900 55.5833C1000 62.8333 1100 82.1667 1150 91.8333L1200 101.5V145H1150C1100 145 1000 145 900 145C800 145 700 145 600 145C500 145 400 145 300 145C200 145 100 145 50 145H0V0Z"
            fill="currentColor"
          ></path>
        </svg>
        <div className="container pt-11 pt-lg-15 pb-9">
          <div className="row pb-8 pb-lg-11">
            <div className="col-lg-10 col-xl-8 mx-auto text-center">
              <h1 className="display-4 mb-3">
                Supported by Entities and Investors
              </h1>
              <p className="lead mb-4"></p>
            </div>
          </div>
        </div>
      </section>

      {/* ::Support Entities:: */}
      <div className="container pb-9 pb-lg-11 position-relative mt-n12">
        <ClientsCarousel />
      </div>
      {/* ::End Support Entities:: */}

      {/* ::Press Kit:: */}
      <section className="position-relative mb-5" id="media-kit">
        <div className="container py-9 py-lg-11 text-break">
          <MediaKit
            link="Download our Media Kit"
            heading="Media Kit"
            shadata="SHA256:a4f6f52c808fecad0151fae8408ca3de38c231fd1d23b211a4a1b9b3647bc726"
            action="/pk/mediakit-verifiedbuzyra-v1-ds8fa9ds87akñj3skcci3.zip"
          />
        </div>
      </section>
      {/* ::End Press Kit:: */}

      {/* ::Call to Action:: */}
      <section className="position-relative" id="try-1-month-free">
        <div className="bg-dark w-100 h-50 bottom-0 start-0 position-absolute"></div>
        <div className="container position-relative">
          <div className="px-4 py-7 py-lg-9 rounded-4 position-relative z-index-1 overflow-hidden text-white bg-secondary">
            <div className="row position-relative">
              <div className="col-lg-9 col-md-10 mx-auto text-center">
                <h5 className="mb-4">
                  Start protecting your digital assets Today
                </h5>
                <h2 className="display-4 mb-5">
                  A complete solution for your cyber protection needs
                </h2>
                <div className="d-flex flex-wrap justify-content-center">
                  <div>
                    <a
                      className="btn btn-white btn-lg hover-lift me-3"
                      href="https://zuratrust.typeform.com/to/UqrUeKsv"
                    >
                      Get your Premium plan for free for 1 month
                      <span className="material-symbols-rounded fs-5 ms-2 align-middle lh-1">
                        arrow_forward
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*}::End Call to Action:: */}

      <Footer />
    </>
  );
}

export default About;
