import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
// import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";

function Features() {
  // Get url hash
  const { hash } = useLocation();

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/features" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/features");
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | Protection Features</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-4 display-3 position-relative z-index-2">
                Protection Features
              </h1>
              <p className="mb-5 w-lg-75 lead">
                Discover how you can improve your cyber security by protecting
                your domains, SSL/TLS certificates and DNS records.
              </p>
              {/* <div className="d-flex justify-content-lg-start justify-content-center mb-3"><a
                                className="btn hover-lift mb-2 btn-gradient-primary btn-lg d-flex align-items-center"
                                href="/contact">Request your Free Trial<span
                                    className="material-symbols-rounded align-middle ms-2">arrow_forward</span></a>
                                    </div> */}
              {/* <div className="mb-5 d-flex align-items-center justify-content-lg-start justify-content-center">
                            <div>
                                <a href="https://www.youtube.com/"
                                    className="glightbox size-60 hover-lift btn btn-warning p-0 rounded-circle d-flex align-items-center justify-content-center"
                                    data-gallery="videoGallery"><span
                                        className="fs-1 align-middle material-symbols-rounded">play_arrow</span></a>
                                        </div>
                            <small className="d-none d-sm-block ms-sm-2">See VbZ in action</small>
                        </div> */}
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img
                    src="/assets/img/features.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
                {/* <div
                                className="bg-white rotate-4 shadow-lg position-absolute end-0 top-0 width-240 h-auto text-dark rounded-4 p-3">
                                <div>
                                    <div className="d-flex align-items-center"><span
                                            className="flex-shrink-0 width-40 height-40 justify-content-center align-items-center d-flex rounded-circle bg-opacity-25 bg-warning text-warning"><span
                                                className="material-symbols-rounded align-middle fs-3">workspaces</span></span>
                                        <div className="flex-grow-1 ps-3">
                                            <h6 className="mb-0 text-reset">Easy collaboration with remote teams</h6>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                {/* <div
                                className="bg-white rotate-n4 shadow-lg position-absolute start-0 bottom-0 width-200 h-auto text-dark rounded-4 p-3">
                                <div>
                                    <div className="d-flex align-items-center"><span
                                            className="flex-shrink-0 width-40 height-40 justify-content-center align-items-center d-flex rounded-circle bg-opacity-25 bg-primary text-primary"><span
                                                className="material-symbols-rounded align-middle fs-3">task</span></span>
                                        <div className="flex-grow-1 ps-3">
                                            <h6 className="mb-0 text-reset">Manage your business</h6>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}

      {/* ::Section with image + Feature Icons:: */}
      <section className="position-relative border-top">
        <div className="container pt-9 pt-lg-11">
          <div className="row align-items-center">
            <div className="col-lg-8 mx-auto text-center mb-7">
              <h2 className="display-5 mb-0">
                "A set of features built around the protection of your digital assets."
              </h2>
              <div className="bg-warning width-80 my-7 mx-auto pt-1 rounded-pill"></div>
            </div>
          </div>
          {/* <div className="pb-9 pb-lg-11 border-bottom">
            <div className="row">
              <div className="col-xl-10 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-6 pe-md-5 pe-lg-7 col-sm-9 mb-6 mb-lg-0">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <div className="position-relative overflow-hidden rounded-3 shadow-lg">
                          <img
                            src="/assets/img/shots/08.png"
                            className="img-fluid rounded-3"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="position-relative overflow-hidden rounded-3 mb-4 shadow-lg">
                          <img
                            src="/assets/img/shots/01.png"
                            className="img-fluid rounded-3"
                            alt=""
                          />
                        </div>
                        <div className="position-relative overflow-hidden mb-4 rounded-3 shadow-lg">
                          <img
                            src="/assets/img/shots/02.png"
                            className="img-fluid rounded-3"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 mx-auto">
                    <h2 className="position-relative ms-md-n3 ms-lg-0 ms-0 me-lg-n5 fs-1 mb-4">
                      Domain Spoofing, Domain Ownership, SSL/TLS certificate and
                      DNS record protection.
                    </h2>
                    <p className="mb-4">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing.
                    </p>
                    <ul className="list-unstyled mb-4 mb-lg-5">
                      <li className="d-flex mb-3 align-items-start">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">
                          check_circle
                        </span>
                        Quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
                      </li>
                      <li className="d-flex mb-3 align-items-start">
                        <span className="material-symbols-rounded align-middle text-warning fs-4 me-3">
                          check_circle
                        </span>
                        Excepteur sint occaecat cupidatat sunt in culpa qui
                        officia deserunt mollit anim id est laborum.
                      </li>
                    </ul>
                    <div>
                      <a className="fw-bold" href="/#">
                        Learn More About Analytics
                        <span className="material-symbols-rounded fs-5 ms-2 align-middle lh-1">
                          arrow_forward
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* ::/End Section with image + Feature Icons/:: */}

      {/* ::Active Monitoring:: */}
      <section className="position-relative" id="protection-modes">
        <div className="container py-9 py-lg-11">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <h5 className="mb-4 text-center">
                Active Monitoring
              </h5>
              <h1 className="w-lg-75 mx-auto display-5 mb-5 text-center">
                Protection for Domains, SSL/TLS certificates and DNS records.
              </h1>
              <div className="bg-warning width-80 my-7 mx-auto pt-1 rounded-pill"></div>
              <div className="row justify-content-around">
                <div className="col-md-5 col-xl-6 mt-7 mt-lg-11">
                  <div>
                    <div className="size-60 d-flex justify-content-center shadow-lg rounded-circle align-items-center mb-4 bg-opacity-25 bg-warning text-warning">
                      <span className="material-symbols-rounded fs-2 align-middle">
                        looks_one{" "}
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-3">Domain Spoofing Protection</h5>
                      <p className="mb-0 text-muted">
                        For protected domains you'll get notification for every
                        spoof attempt on the domain. Domains can be spoofed in
                        various ways, and can be very effective in phishing and
                        brand related attacks. VerifiedByZura will monitor and
                        protect your brand from the following spoofing attacks
                        on your domain address: Character Addition, Insertion,
                        Omission and Repetition attacks, Bitsquatting attacks,
                        Homoglyph attacks, Hyphenation attacks, Replacement and
                        vowel-swap attacks, Subdomain attacks, Transposition
                        attacks and Dictionary attack.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-xl-6 mt-7 mt-lg-11">
                  <div>
                    <div className="size-60 d-flex justify-content-center shadow-lg rounded-circle align-items-center mb-4 bg-opacity-25 bg-warning text-warning">
                      <span className="material-symbols-rounded fs-2 align-middle">
                        looks_two{" "}
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-3">Domain Ownership Protection</h5>
                      <p className="mb-0 text-muted">
                        Monitoring domain ownership changes helps being vigilant
                        and protect owned digital assets from accidental lost or
                        theft. An email address change, missed email in an
                        inbox, spam folder fatigue,... can lead to a domain
                        address not being renewed and therefore to costly
                        procedures or even the address being lost forever.
                        Monitoring those crtical dates and changes helps in
                        protecting them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-xl-6 mt-7 mt-lg-11">
                  <div>
                    <div className="size-60 d-flex justify-content-center shadow-lg rounded-circle align-items-center mb-4 bg-opacity-25 bg-warning text-warning">
                      <span className="material-symbols-rounded fs-2 align-middle">
                        looks_3{" "}
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-3">SSL/TLS Certificate Protection</h5>
                      <p className="mb-0 text-muted">
                        With the wide addoption of SSL/TLS certificates, anyone
                        willing to impersonate a pulic facing service needs to
                        generate a valid certificate in order to appear legit.
                        VeryfiedByZura keeps track and monitors those
                        certificates, in order to detect any change in unique
                        traits that would suggest a suspicious sign. Also helps
                        simply being the first to know when a certificate has
                        expired.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-xl-6 mt-7 mt-lg-11">
                  <div>
                    <div className="size-60 d-flex justify-content-center shadow-lg rounded-circle align-items-center mb-4 bg-opacity-25 bg-warning text-warning">
                      <span className="material-symbols-rounded fs-2 align-middle">
                        looks_4{" "}
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-3">DNS Record Protection</h5>
                      <p className="mb-0 text-muted">
                        DNS record spoofing attacks can lead to email account
                        compromises, asset kidnapping or even irreversible theft
                        of valuable digital assets. By changing an organisations
                        DNS records a wide range of attacks can be executed,
                        with painful economical and credibility effects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End Active MOnitoring/:: */}

      {/* ::account security:: */}
      <section className="position-relative border-top" id="account-security">
        <div className="container py-9 py-lg-11">
          <div className="row">
            <div className="col-lg-12 mx-auto text-center">
              <h5 className="mb-4">Account Security</h5>
              <h1 className="w-lg-75 mx-auto display-5 mb-5">
                Account Protection
              </h1>
              <div className="bg-warning width-80 my-7 mx-auto pt-1 rounded-pill"></div>
              <div className="row mb-5 justify-content-center">
                <div className="col-lg-12 mx-auto text-center mb-7">
                  <p className="lead mb-0">
                    Account activity is monitoired and suspicious protection
                    changes trigger a notification to th accopunt holders email
                    address. In addition to this, you protection settings,
                    protection analysis results and account access data are
                    stored encrypted and airgapped. In order to protect you
                    assest, we start by protecting ours.
                  </p>
                  {/* <div className="bg-warning width-80 my-7 mx-auto pt-1 rounded-pill">sss</div> */}
                </div>
              </div>
              {/* <div className="text-center">
                <Link to="/features#notification-channels">View more</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* ::/End account security/:: */}

      {/* ::notification channels:: */}

      <section
        className="position-relative border-top"
        id="notification-channels"
      >
        <div className="container py-9 py-lg-11">
          <div className="row">
            <div className="col-lg-12 mx-auto ">
              <h5 className="mb-4 text-center">
                Notifications Channels
              </h5>
              <h1 className="w-lg-75 mx-auto display-5 mb-5 text-center">
              Be Notified on Multiple Channels when an Asset might be at Risk.
              </h1>
              <div className="bg-warning width-80 my-7 mx-auto pt-1 rounded-pill"></div>
              {/* <div className="row mb-5 justify-content-center"></div> */}

              <div className="row">
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-primary">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/email.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                      {/* <span className="material-symbols-rounded align-middle fs-1">
                        insights
                       </span> */}
                    </div>
                    <h5 className="mb-3">Email</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Receive verifiedByZura service notifications via email to
                      multiple custom recipients for each event type.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-success">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/sms.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">SMS</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Receive verifiedByZura service notifications via SMS in
                      your mobile device.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-primary">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/slack.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Slack</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Publish verifiedByZura service notifications to the Slack
                      channel of your choice.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-success">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/whatsapp.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Whatsapp</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Publish verifiedByZura service notifications to the
                      Whatsapp person or group of your choice.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-warning">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/telegram.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Telegram</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Publish verifiedByZura service notifications to the
                      Telegram person or group of your choice.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-warning">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/webhooks.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Webhooks</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Publish verifiedByZura service notifications and enable
                      automation flows via Webhooks. Pick different webhook
                      endpoint for each event type.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-success">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/elasticopensearch.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Elastic/Open Search</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap">
                      Publish verifiedByZura service notifications to Elastic
                      Search or Open Search for future search or analytics data
                      usage.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-warning">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/awscloudwatch.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Aws Cloudwatch</h5>
                    <p className="mb-0 pe-xl-5 text-start">
                      Publish verifiedByZura service notifications to Amazon AWS
                      Cloudwatch for future search or analytics data usage.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-6 mb-md-0 mt-5">
                  <div className="">
                    <div className="mb-4 text-primary">
                      <span className="align-middle fs-1">
                        <img
                          src="/assets/img/integrations/discord.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <h5 className="mb-3">Discord</h5>
                    <p className="mb-0 pe-xl-5 text-start word-wrap min">
                      Publish verifiedByZura service notifications to Discord.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="text-center">
                <Link to="/features#notification-channels">View more</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* ::/End notification channels/:: */}

      {/* ::Call to Action:: */}
      <section className="position-relative" id="try-1-month-free">
        <div className="bg-dark w-100 h-50 bottom-0 start-0 position-absolute"></div>
        <div className="container position-relative">
          <div className="px-4 py-7 py-lg-9 rounded-4 position-relative z-index-1 overflow-hidden text-white bg-secondary">
            <div className="row position-relative">
              <div className="col-lg-9 col-md-10 mx-auto text-center">
                <h5 className="mb-4">
                  Start protecting your digital assets Today
                </h5>
                <h2 className="display-4 mb-5">
                  A complete solution for your cyber protection needs
                </h2>
                <div className="d-flex flex-wrap justify-content-center">
                  <div>
                    <a
                      className="btn btn-white btn-lg hover-lift me-3"
                      href="https://zuratrust.typeform.com/to/UqrUeKsv"
                    >
                      Get your Premium plan for free for 1 month
                      <span className="material-symbols-rounded fs-5 ms-2 align-middle lh-1">
                        arrow_forward
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*}::End Call to Action:: */}

      <Footer />
    </>
  );
}

export default Features;
