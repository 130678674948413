import React from "react";
import {Helmet} from "react-helmet";
// import logo from './logo.svg';
// import './App.css';
import Home from "./pages/home";
import LegalTermsofuse from "./pages/legaltermsofuse";
import LegalCookies from "./pages/legalcookies";
import LegalPrivacy from "./pages/legalprivacy";
import About from "./pages/about";
import Features from "./pages/features";
import Page404 from "./pages/page404";
import {
  Routes,
  Route,
} from "react-router-dom";
// Bootstrap CSS
import "./scss/theme.scss";
// Bootstrap Bundle JS
// import "./js/theme.js";
import 'bootstrap';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-5RMNS4R41R");

function App() {

  return (
    <div className="App">
      
      <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href="https://www.verifiedbyzura.com" />
          <meta name="description" content="verifiedbyzura.com | Protect your digital assets" />
          <meta name="theme-color" content="#000000" />
          <link rel="icon" href="/favicon.ico" />
          {/* <title>My Title 22</title> */}
          <link rel="apple-touch-icon" href="/logo192.png" />
          <link rel="manifest" href="/manifest.json" />

          {/* :Google fonts: */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href='https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;1,400;1,500&family=Poppins:wght@200;300;400;500;600;700&display=swap' rel="stylesheet" />

          {/* :Material symbols sharp icons: */}
          <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0" />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/legal/privacy-policy" element={<LegalPrivacy />} />
        <Route exact path="/legal/terms-of-use" element={<LegalTermsofuse />} />
        <Route exact path="/legal/cookie-policy" element={<LegalCookies />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/features" element={<Features />} />
        <Route path="*" element={<Page404 />} />
      </Routes>

        
    </div>
  );
}

export default App;
