// import React from "react";
import ReactGA from "react-ga4";

export function Pageview(_path) {
//   ReactGA.send("pageview");
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: _path });
}

export function SpecialEvent() {
  // Send a custom event
  ReactGA.event({
    category: "your category",
    action: "your action",
    label: "your label", // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
}
