export default function PricingComparison() {
  return (
    <>
      <div className="row mb-5 mb-lg-7 pt-3">
        <div className="col-lg-12 mt-8 mb-3 mx-auto text-center">
          {/* <h5 className="mb-4">Service options</h5> */}
          <h4 className="display-6 mt-5 mb-4">Plan comparison</h4>
        </div>
        <div className="col-lg-12 mx-auto text-center">
          <div className="table-responsive">
            <table className="table table-striped text-center text-nowrap mb-0">
              <thead>
                <tr>
                  <th style={{ minWidth: "40%" }}></th>
                  <th style={{ minWidth: "20%" }}>
                    <h5 className="mb-0">Free</h5>
                  </th>
                  <th style={{ minWidth: "20%" }}>
                    <h5 className="mb-0 text-warning">Premium</h5>
                  </th>
                  <th style={{ minWidth: "20%" }}>
                    <h5 className="mb-0">Premium<br/>Unlimited</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="text-start">
                    Domains protected{" "}
                    <sup>
                      <strong>1</strong>
                    </sup>
                  </th>
                  <td>
                    <span className="fs-6">1</span>
                  </td>
                  <td>
                    <span className="fs-6">4</span>
                  </td>
                  <td>
                    <span className="fs-6">Unlimited</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Email Notification Channel
                  </th>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Monitoring Frequence
                  </th>
                  <td>
                    <span className="fs-6">
                      Best Effort{" "}
                      <sup>
                        <strong>2</strong>
                      </sup>
                    </span>
                  </td>
                  <td>
                    <span className="fs-6">
                      Guaranteed{" "}
                      <sup>
                        <strong>3</strong>
                      </sup>
                    </span>
                  </td>
                  <td>
                    <span className="fs-6">
                      Guaranteed{" "}
                      <sup>
                        <strong>3</strong>
                      </sup>
                    </span>
                  </td>
                </tr>

                <tr>
                  <th scope="row" className="text-start">
                    Domain Spoofing Protection{" "}
                    <sup>
                      <strong>1</strong>
                    </sup>
                  </th>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Domain Ownership Protection{" "}
                    <sup>
                      <strong>1</strong>
                    </sup>
                  </th>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    SSL/TLS Certificate Protection{" "}
                    <sup>
                      <strong>1</strong>
                    </sup>
                  </th>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    DNS Record Protection{" "}
                    <sup>
                      <strong>1</strong>
                    </sup>
                  </th>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                    
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                {/* </tbody>

              <tbody> */}
                <tr>
                  <th scope="row" className="text-start">
                    Advanced Notification Channels
                  </th>
                  <td></td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Standard Support
                  </th>
                  <td></td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Analysis Logs with Limited History
                  </th>
                  <td></td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                  <td>
                    <span className="align-middle fs-3 text-success material-symbols-rounded">
                      check
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Analysis Logs (traceback)
                  </th>
                  <td></td>
                  <td>
                    <span className="fs-6">Limited History</span>
                  </td>
                  <td>
                    <span className="fs-6">Full History</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start">
                    Support
                  </th>
                  <td></td>
                  <td>
                    <span className="fs-6">Standard</span>
                  </td>
                  <td>
                    <span className="fs-6">Premium</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-start"></th>
                  
                  
                  <td>
                  
                    <span className="fs-6">
                  <a
                  className="btn mb-2 btn-secondary btn-lg d-flex align-items-center w-100 text-center justify-content-center p-2"
                  href="https://app.verifiedbyzura.com/#starter-plan"
                >
                  Get
                </a>
                </span>
                  
                  </td>
                  <td>
                    <span className="fs-6">
                    <a
                  className="btn mb-2 btn-warning btn-lg d-flex align-items-center w-100 text-center justify-content-center p-2"
                  href="https://app.verifiedbyzura.com/#premium-plan"
                >
                  Get
                </a>
                    </span>
                  </td>
                  <td>
                    <span className="fs-6">
                    <a
                  className="btn mb-2 btn-secondary btn-lg d-flex align-items-center w-100 text-center justify-content-center p-2"
                  href="https://app.verifiedbyzura.com/#premium-unlimited-plan"
                >
                  Get
                </a>
                    </span>
                  </td>
                </tr>


                

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mx-auto">
        <blockquote className="blockquote d-none d-sm-block ms-sm-2 mt-4">
          <footer className="blockquote-footer">
            <small>
              <sup>
                <strong>1</strong>
              </sup>{" "}
              When domains are limited in the plan, all ownership, spoofing, dns
              and ssl/tls protections must be related to that parent domain.
            </small>
          </footer>
          <footer className="blockquote-footer">
            <small>
              <sup>
                <strong>2</strong>
              </sup>{" "}
              Best Effort monitoring frequence: Analyses and protection checks
              will be performed on a resource availability basis (expect an
              average of 2 checks per month). 
            </small>
          </footer>
          <footer className="blockquote-footer">
            <small>
              <sup>
                <strong>3</strong>
              </sup>{" "}
              Guaranteed monitoring frequence: protection checks performed at least once
              a day for all assets and protection methods (expect more than 1 check per day).
            </small>
          </footer>
        </blockquote>
      </div>
    </>
  );
}
