import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {useLocation} from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import Header from "../components/header";
import Footer from "../components/footer";
import { Pageview } from "../components/analytics";
import { navigateToAnchor } from "../components/utils";

function Legal() {
  // Get url hash
  const { hash } = useLocation();

  useEffect(() => {
    // console.log("useeffect hash");
    // console.log(hash);
    if (hash !== "") {
      navigateToAnchor(hash);
      Pageview("/legal/privacy-policy" + hash);
    } else {
      navigateToAnchor("");
      Pageview("/legal/privacy-policy");
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>verifiedbyzura.com | Our Privacy Policy</title>
      </Helmet>
      <Header />

      {/* ::Hero Default:: */}
      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-10 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h1 className="mb-4 display-3 position-relative z-index-2">
                Our Privacy Policy
              </h1>
              <p className="mb-5 w-lg-75 ">
                current version: v1.4 as of 01-2022
              </p>
            </div>
            <div className="col-lg-6 col-md-10 mx-auto mx-lg-0 position-relative">
              <div className="position-relative w-100 p-3 p-lg-5">
                <div className="position-relative w-100 h-auto">
                  <img
                    src="/assets/img/balance-legal.png"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ::/End hero default/:: */}

      <section className="pt-12 pt-lg-15 pb-7 bg-blur position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <h2>
                Transparency is important for us, and this is our privacy
                Policy.
              </h2>
              <h3>
                (Currently this information is only available is spanish, other
                languages are WIP.)
              </h3>
            </div>
            <div className="col-lg-12 text-lg-start ms-lg-0 me-lg-auto mx-md-auto">
              <p>
                Zura Technologies SLU (en adelante 'VerifiedByZura') domiciliada
                en Deusto Entrepreneurship Center – Innogune – Mundaiz 50 –
                20012 Donostia-San Sebastián y correo electrónico de contacto:
                hello@zuratec.com
              </p>
              <p>
                Puede contactar con nuestro Delegado de Protección de Datos
                (DPO) a través del siguiente email: hello@zuratec.com
              </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" colSpan="2">
                      <h3>Información Básica Sobre Protección De Datos</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Responsable del tratamiento</th>
                    <td>
                      VerifiedByZura, Zura technologies SLU <br />
                      (Deusto Entrepreneurship Center - Innogune
                      <br /> Mundaiz bidea 50, 20012 Donostia-San Sebastián){" "}
                      <br /> Basque Country - Spain) <br />
                      CIF: B75179986
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Finalidad del tratamiento</th>
                    <td>
                      Prestación servicio comprobación y monitorización de la
                      seguridad de activos digitales y lucha contra técnicas de
                      fraude digital
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Legitimación del tratamiento</th>
                    <td>Consentimiento del usuario/a</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Destinatarios de cesiones o transferencias
                    </th>
                    <td>No se prevén cesiones</td>
                  </tr>
                  <tr>
                    <th scope="row">Derechos de las personas interesadas</th>
                    <td>
                      Acceder, rectificar y suprimir los datos, así como otros
                      derechos, como se explica en el apartado correspondiente.
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Información adicional</th>
                    <td>
                      Puede consultar la información adicional y detallada sobre
                      Protección de Datos a continuación.
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h3>¿Qué datos personales trata Zura?</h3>
              <p>
                VerifiedByZura realiza el tratamiento de los siguientes datos
                personales
              </p>
              <ul>
                <li>
                  <p>
                    Datos de perfil del usuario/a: Nombre, apellidos, número de
                    teléfono, correo electrónico, y datos relacionados con
                    facturación de la persona u organización contratante.
                  </p>
                </li>
                <li>
                  <p>
                    Información pública rebacada de forma sistemática a través
                    de múltiples métodos.
                  </p>
                </li>
              </ul>
              <h3>¿Con qué finalidad trataremos sus datos personales?</h3>
              <p>
                VerifiedByZura realiza tratamientos de dichos datos personales
                de forma automatizada con la finalidad de prestar el servicio de
                protección de activos digitales.
              </p>
              <h3>¿Durante cuanto tiempo conservaremos sus datos?</h3>
              <p>
                VerifiedByZura conservará los datos personales desde el momento
                que el usuario/a presta su consentimiento hasta que solicite, se
                de de baja o retire su consentimiento para el tratamiento de
                datos.
              </p>
              <p>
                Posibilidad de retirar el consentimiento: en el caso de que se
                haya otorgado el consentimiento, el usuario/a tiene derecho a
                retirar el consentimiento en cualquier momento, sin que ello
                afecte a la licitud del tratamiento basado en el consentimiento
                previo a su retirada.{" "}
              </p>
              <h3>¿A que otros destinatarios se comunicarán sus datos?</h3>
              <p>
                En ningún caso VerifiedByZura cederá los datos personales a
                ninguna otra entidad o institución, más allá del cumplimiento de
                las obligaciones legales o mandamientos judiciales que pudieran,
                en su caso, corresponderle. Asimismo ha firmado los
                correspondientes contratos de encargado de tratamiento con sus
                proveedores a fin asegurar la confidencialidad y el cumplimiento
                del Reglamento General de Protección de Datos.{" "}
              </p>
              <p>
                En cuanto a transferencias internacionales de datos, no se
                prevén su realización.
              </p>
              <h3>¿Qué derechos tiene el usuario/a y como puede ejercerlos?</h3>
              <p>
                Los usuarios/as pueden ejercitar los derechos que le
                corresponden en la materia mediante comunicación por escrito al
                domicilio social de VerifiedByZura o a la dirección de correo
                electrónico indicado en el encabezamiento de este aviso legal,
                incluyendo en ambos casos fotocopia de su DNI u otro documento
                identificativo similar, para solicitar el ejercicio de los
                derechos siguientes:
              </p>
              <ul>
                <li>
                  <p>
                    Derecho a solicitar el acceso a los datos personales: usted
                    podrá preguntar a VerifiedByZura si está tratando sus datos.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Derecho a solicitar su rectificación (en caso de que sean
                    incorrectos) o supresión.
                  </p>
                </li>
                <li>
                  <p>
                    Derecho a solicitar la limitación de su tratamiento, en cuyo
                    caso únicamente serán conservados por VerifiedByZura para el
                    ejercicio o la defensa de reclamaciones.
                  </p>
                </li>
                <li>
                  <p>
                    Derecho a oponerse al tratamiento: VerifiedByZura dejará de
                    tratar los datos en la forma que usted indique, salvo que
                    por motivos legítimos imperiosos o el ejercicio o la defensa
                    de posibles reclamaciones se tengan que seguir tratando.
                  </p>
                </li>
                <li>
                  <p>Derecho a la portabilidad de los datos.</p>
                </li>
              </ul>

              <h3>
                Modelos, formularios y más información sobre los derechos
                referidos:
              </h3>
              <p>
                <a
                  href="https://www.aepd.es/reglamento/derechos/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Página oficial de la Agencia Española de Protección de Datos
                </a>
              </p>

              <h3>Cómo reclamar ante la Autoridad de Control:</h3>
              <p>
                Si un usuario/a considera que hay un problema con la forma en
                que VerifiedByZura está tratando sus datos, puede dirigir sus
                reclamaciones al DPD (o DPO) de VerifiedByZura o a la autoridad
                de protección de datos que corresponda, siendo la{" "}
                <a
                  href="https://www.aepd.es/reglamento/derechos/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Agencia Española de Protección de Datos
                </a>{" "}
                la indicada en el caso de España.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Legal;
